import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from '@xstyled/styled-components';
import getLinkPath from 'utils/getLinkPath';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import { useAtom } from 'jotai';
import { currentPageAtom } from 'global/store';

const MenuItems = styled.div`
  display: flex;

  &:not(:last-of-type) {
    flex: auto;
  }

  a {
    flex: 1;
    padding-bottom: 1rem;
    font-weight: 500;

    &:hover,
    &:active {
      color: teal-400;
    }
  }
`;

const SubMenu = styled.div`
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 2.5rem;
  left: 0;
  padding: 3rem;
  z-index: 3;
  background-color: white;
  box-shadow: 0px 0px 12px 2px rgba(247, 252, 254, 1);
  border-radius: 0 0 30px 30px;

  nav a {
    font-size: small;
    color: teal-300;
    font-weight: 500;
    line-height: 1.7;
  }

  nav > div a {
    color: black;
    font-size: 0.9rem;
  }
`;

export default function Menu({ category }) {
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);

  const [showMenu, setShowMenu] = useState(false);

  const categoryPath =
    category.name === 'Blogi' ? '/blogi' : getLinkPath(category.url_key);
  return (
    <MenuItems
      onMouseEnter={() =>
        category.name !== 'Blogi' &&
        category.name !== 'Kinkekaart' &&
        category.children.length !== 0 &&
        setShowMenu(true)
      }
      onMouseLeave={() => setShowMenu(false)}
      onClick={() => setShowMenu(false)}
    >
      <Link href={categoryPath}>
        <a
          onClick={e => {
            setCurrentPage(1);
          }}
        >
          {category.name}
        </a>
      </Link>

      {showMenu && (
        <SubMenu>
          <FlexBox
            display="grid"
            gridTemplateColumns="repeat(3, 1fr)"
            columnGap="3rem"
          >
            {category.children?.map(parent => {
              return (
                <nav key={parent.url_key}>
                  {parent.products?.total_count >= 0 && (
                    <Link href={getLinkPath(parent.url_key, category.url_key)}>
                      <a>{parent.name}</a>
                    </Link>
                  )}

                  {parent?.children?.map(child => {
                    return (
                      <>
                        {child.product_count >= 0 && (
                          <div key={child.url_key}>
                            <Link
                              href={getLinkPath(
                                child.url_key,
                                parent.url_key,
                                category.url_key
                              )}
                            >
                              <a
                                onClick={e => {
                                  setCurrentPage(1);
                                }}
                              >
                                {child.name}
                              </a>
                            </Link>
                          </div>
                        )}
                      </>
                    );
                  })}
                </nav>
              );
            })}
          </FlexBox>

          {category?.image && (
            <Image
              src={category?.image}
              alt={category.name}
              width={152.5}
              height={267}
            />
          )}
        </SubMenu>
      )}
    </MenuItems>
  );
}
